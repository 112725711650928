<!--
	@component:	WEBDOWNLOADJOBS
	@desc:		Wrap component to show list of current download jobs
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2019-01-18
 -->
<template>
  <div v-loading="loading">
    <div class="p-1 flex space-between b-b">
      <el-radio-group v-model="activeTab" @change="handleRadioChange">
        <el-radio-button :label="1">{{ $t('MY') }} ({{ myItemsCount }})</el-radio-button>
        <el-radio-button :label="2">{{ $t('ALL') }} ({{ allItemsCount }})</el-radio-button>
      </el-radio-group>

      <el-button type="default" class="m-l-1" @click="requestDownloadJobs" v-loading="loading">
        <i class="fa fa-refresh m-r-small"></i>
        {{ $t('REFRESH') }}
      </el-button>
    </div>

    <ListDownloadJobs :items="items" />
  </div>
</template>

<script>
import ListDownloadJobs from '@/components/List/ListDownloadJobs';

export default {
  name: 'WEBDOWNLOADJOBS',

  components: {
    ListDownloadJobs
  },

  computed: {
    allItems() {
      return this.$store.state.webdownloadjobs.node;
    },

    allItemsCount() {
      return this.allItems.length;
    },

    myItems() {
      return this.$store.getters['webdownloadjobs/MY_JOBS'];
    },

    myItemsCount() {
      return this.myItems.length;
    }
  },

  data() {
    return {
      activeTab: 1,
      items: [],
      loading: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    handleRadioChange(item) {
      switch (item) {
      /* eslint-disable */
        case 1:
        this.items = this.myItems.slice(0);
        break;
      case 2:
        this.items = this.allItems.slice(0);
        break;
      }
    },

    init() {
      this.requestDownloadJobs();
    },

    requestDownloadJobs() {
      this.loading = true;

      this.$store
        .dispatch('webdownloadjobs/GET_JOBS')
        .then(() => {
          this.loading = false;
          this.handleRadioChange(this.activeTab);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss"></style>
