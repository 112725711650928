<!--
	@component:	ListDownloadJobs
	@desc:		Show list of current download jobs
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2019-01-18
 -->
<template>
  <ul class="list no-margin no-padding font-brand color-dark">
    <li
      class="list-item b-b"
      style="padding: 1rem 1.2rem"
      v-for="item in items"
      :key="item.id"
      :class="[{ 'is-highlighted': item.XUSER === user.userlogin }, { 'list-item--danger': parseInt(item.SESSIONSTATUS) >= 900 }]"
      :title="item.MACHINENAME"
    >
      <div class="flex">
        <div class="m-r-1">
          <p class="no-margin color-dark">#{{ item.id + 1 }}</p>
        </div>

        <div>
          <p class="no-margin m-b-half">{{ item.MACHINENAME }}</p>
          <p class="no-margin font--small color-dark-light">
            {{ item.DATECREATED }} // {{ item.SESSIONNUMBER }}
            <span v-if="item.XUSER">//</span>
            {{ item.XUSER }}
          </p>
        </div>
      </div>

      <div class="a-right--md flex--md m-t-1--sm" style="flex: 1 0 auto">
        <span>{{ item.STATUSTEXT }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ListDownloadJobs',

  props: {
    items: {
      type: Array,
      required: false
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>

<style lang="scss">
.list-item {
  border-left: 5px solid transparent;

  @media screen and (min-width: $screen-md) {
    display: flex;
    align-items: center;
  }

  &.is-highlighted {
    background-color: transparentize($c-brand, 0.95);
    border-left-color: $c-brand;
  }

  &--danger {
    background-color: transparentize($c-danger, 0.95);
    border-left-color: $c-danger;
  }
}
</style>
